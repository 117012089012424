import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    possibleCharges: Array
  }

  static targets = [
    'addLineItem'
  ]

  connect() {
    const self = this

    $(this.addLineItemTarget).on('click', this.handleAdd)

    this.linkDeleteEvent()
    this.checkDeleteIcon()

    this.customerField = $(this.element).find('input[name="invoice[customer_id]"]')
    if (!this.customerField.parents('.field').hasClass('disabled')) {
      this.customerField.on('change', function() {
        self.handleCustomerChange()
      })
    }

    this.objectField = $(this.element).find('input[name="invoice[object]"]')
    if (!this.objectField.parents('.field').hasClass('disabled')) {
      this.objectField.on('change', function() {
        self.handleObjectChange()
      })
    }

    self.setupDropdowns()
  }

  setupDropdowns() {
    const self = this
    $('.ui.charge-selection.dropdown').dropdown({
      onChange: function(value) {
        const charge = self.getCharge(value)

        $(this).parents('.line-item').find('.tf-li-description input').val(charge['inv_desc'])
        $(this).parents('.line-item').find('.tf-li-unit-price input').val(charge['amount'])
      }
    })
  }

  getCharge(gid) {
    return this.possibleChargesValue.find(cv => cv['gid'] === gid)
  }

  handleCustomerChange() {
    const fd = new FormData(this.element)
    const path = document.location.pathname
    const params = new URLSearchParams()

    for (const [k, v] of fd.entries()) {
      if (k == 'invoice[customer_id]') {
        params.append('customer_id', v)
      }
    }

    Turbolinks.visit(`${path}?${params.toString()}`, { action: 'replace' })
  }

  handleObjectChange() {
    const fd = new FormData(this.element)
    const path = document.location.pathname
    const params = new URLSearchParams()

    for (const [k, v] of fd.entries()) {
      if (k == 'invoice[customer_id]') {
        params.append('customer_id', v)
      } else if (k == 'invoice[object]') {
        params.append('object', v)
      }
    }

    Turbolinks.visit(`${path}?${params.toString()}`, { action: 'replace' })
  }

  linkDeleteEvent = () => {
    $(this.element).find('.trash.link').off('click')
    $(this.element).find('.trash.link').on('click', this.handleDelete)
  }

  handleDelete = (e) => {
    const lineItemContainer = $(e.target).parents('.line-item')

    lineItemContainer.remove()
    this.checkDeleteIcon()
  }

  checkDeleteIcon = () => {
    if($('.line-item-container').children('.line-item:not(".deleted")').length <= 1) {
      this.deleteIcon = $('.line-item-container .trash').prop('outerHTML')
      $('.line-item-container .trash').remove()
    }
  }

  handleAdd = (e) => {
    e.preventDefault()

    const idx = Date.now()

    $('.line-item-container').append(`<div class="line-item">
      <div class="equal width fields">
        <div class="required field">
          <label for="invoice_line_items_attributes_${idx}_charge_gid">Charge</label>
          <div class="ui fluid charge-selection selection dropdown" data-index="${idx}">
            <input autocomplete="off" type="hidden" name="invoice[line_items_attributes][${idx}][charge_gid]" id="invoice_line_items_attributes_${idx}_charge_gid" class="noselection">
            <i class="dropdown icon"></i>
            <div class="default text">Charge</div>
            <div class="scrollhint menu">
              ${this.possibleChargesValue.map(cv => (
                `<div class="item" data-value="${cv['gid']}">${cv['desc']}</div>`
              )).join("\n")}
            </div class="scrollhint menu">
          </div>
        </div>

        <div class="required tf-li-description field">
          <label for="invoice_line_items_attributes_${idx}_description">Description</label>
          <input id="invoice_line_items_attributes_${idx}_description" type="text" name="invoice[line_items_attributes][${idx}][description]">
        </div>

        <div class="required tf-li-unit-price field">
          <label for="invoice_line_items_attributes_${idx}_unit_price">Unit Price</label>
          <input id="invoice_line_items_attributes_${idx}_unit_price" type="number" step=".01" name="invoice[line_items_attributes][${idx}][unit_price]">
        </div>

        <div class="required field">
          <label for="invoice_line_items_attributes_${idx}_quantity">Quantity</label>
          <input id="invoice_line_items_attributes_${idx}_quantity" type="number" value="1" name="invoice[line_items_attributes][${idx}][quantity]">
        </div>

        <i class="large trash icon link mt-2"></i>
      </div>
    </div>`)

    this.setupDropdowns()
    this.linkDeleteEvent()
  }
}
